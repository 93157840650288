<template>
  <div class="_firstPage">
    <div class="liangjie">
      <!-- 毫无意义的两部分 -->
      <!-- 不明意义的图片 -->
      <div class="guan">
        <el-image
          style="width: 100%; height: 100%"
          :src="onetu"
          fit="../../assets/img/new/guangao.png"
        ></el-image>
        <!-- <img
          src="../../assets/img/new/guangao.png"
          class="w-full h-full"
          alt=""
        /> -->
      </div>
      <!-- 为什么放上面?? -->
      <div class="why">
        <div class="fang" @click="xie(0)">
          <div class="icon">
            <img
              src="../../assets/img/new/one.png"
              class="w-full h-full"
              alt=""
            />
          </div>
          <div>我要咨询</div>
        </div>

        <div class="xixian"></div>
        <div class="fang" @click="xie(2)">
          <div class="icon">
            <img
              src="../../assets/img/new/three.png"
              class="w-full h-full"
              alt=""
            />
          </div>
          <div>我要建议</div>
        </div>

        <div class="xixian"></div>

        <div class="fang" @click="xie(1)">
          <div class="icon">
            <img
              src="../../assets/img/new/two.png"
              class="w-full h-full"
              alt=""
            />
          </div>
          <div>我要投诉</div>
        </div>

        <div class="xixian"></div>

        <div class="fang" @click="chaxunn(0)">
          <div class="icon">
            <img
              src="../../assets/img/new/four.png"
              class="w-full h-full"
              alt=""
            />
          </div>
          <div>文明实践</div>
        </div>

        <div class="xixian"></div>

        <div class="fang" @click="chaxunn(1)">
          <div class="icon">
            <img
              src="../../assets/img/new/five.png"
              class="w-full h-full"
              alt=""
            />
          </div>
          <div>啄木鸟</div>
        </div>
        
      </div>
      <div class="zhijian hand-cursor" @click="tozhijian()" @mouseover="hover = true" @mouseleave="hover = false">
        <el-image
          style="width: 100%; height: 100%"
          :src="zhijian"
          fit="../../assets/img/new/zhijian.png"
        ></el-image>
      </div>
     
    </div>
  
    <!-- 轮播图 -->
    <div class="lun">
      <!-- 轮播图 -->
      <div class="zuo">
        <el-carousel indicator-position="outside" @change="jizhang">
          <el-carousel-item v-for="(item, index) in arrimg" :key="index">
            <img
              :src="item.images[0]"
              class="w-full h-full cursor_pointer"
              alt=""
              @click.stop="godetail(item.sharesalt)"
            />
          </el-carousel-item>
        </el-carousel>
        <div class="zhanshi">
          <!-- 名展示 -->
          <div class="fontone">{{ luntitle.name }}</div>
          <!-- 圈展示 -->
          <div class="flex h-full">
            <!-- 模板 -->
            <div class="quan" v-for="(item, index) in arrimg" :key="index">
              <img
                src="../../assets/img/new/hui.png"
                v-if="lunnum != index"
                class="w-full h-full"
                alt=""
              />
              <img
                src="../../assets/img/new/bai.png"
                v-else
                class="w-full h-full"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 信息 -->
      <div class="you">
        <wone></wone>
      </div>
    </div>
    <!-- 热点 -->
    <div class="redian min_width">
      <div class="_re">
        <div class="flex w-full">
          <!-- :class="{ zuoyin: kaihenum == 1, zuohui: kaihenum == 0 }" -->
          <div class="left" v-if="kaihenum != 1">
            <div class="leftt">
              <!-- 标题 -->
              <div class="biao">
                <img
                  src="../../assets/img/new/jujiao.png"
                  class="h-full"
                  style="width: 478px"
                  alt=""
                />
              </div>
              <div class="cardd">
                <div>
                  <!-- 模板 -->
                  <div
                    style="margin-top: 25px"
                    v-for="(item, index) in focusA"
                    :key="index"
                    @click="xiangqing(item.cardsalt)"
                    class="cursor_pointer"
                  >
                    <!-- 问  -->
                    <div class="flex align-items">
                      <div class="wen">
                        <img
                          src="../../assets/img/new/wen.png"
                          class="w-full h-full"
                          alt=""
                        />
                      </div>
                      <div
                        style="width: 94%"
                        class="fonttwo flex space-between"
                      >
                        <div class="gengbiaoti">{{ item.title }}</div>
                        <span class="fontfour">{{ item.articletime }}</span>
                      </div>
                    </div>
                    <!-- 答 -->
                    <div class="flex huida">
                      <img
                        src="../../assets/img/new/da.png"
                        class="da"
                        alt=""
                      />

                      <!-- <div class="fontThre">
                        {{ wenxian(item.content) }}
                      </div> -->
                      <div class="fontThre" v-html="item.content">
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 更多 -->
                <div
                  class="flex justify-content duo"
                  :class="{ opacity: kaihenum == 1 }"
                >
                  <div class="flex align-items cursor_pointer" @click="rjujiao">
                    <div class="fontfive">查看更多</div>
                    <img
                      src="../../assets/img/new/geng.png"
                      class="geng"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="biaot">
              <img
                src="../../assets/img/new/danwei.png"
                v-if="kaihenum != 1"
                alt=""
              />
              <img src="../../assets/img/new/quanshouli.png" v-else alt="" />
            </div>
            <!-- :class="{ xzb: kaihenum == 1, hutoub: kaihenum == 0 }" -->
            <!-- :class="{ pbj: kaihenum != 1 }" -->
            <div class="cardds">
              <div class="zhankai" @click="kaihe">
                <img
                  src="../../assets/img/new/zhankai.png"
                  class="zhan"
                  alt=""
                  :class="{ xzb: kaihenum == 1, hutoub: kaihenum == 0 }"
                />
                <div v-if="kaihenum != 1">
                  <div class="ziti">展</div>
                  <div class="ziti">开</div>
                  <div class="ziti">更</div>
                  <div class="ziti">多</div>
                </div>
                <div v-else>
                  <div class="ziti">收</div>
                  <div class="ziti">起</div>
                </div>
              </div>
              <!-- 部门
              模板(热点部门) -->
              <div
                style="padding: 20px 0 0 0"
                class="flex flex-wrap"
                v-if="kaihenum != 1"
              >
                <div
                  class="redanwei"
                  v-for="(res, index) in relist"
                  :key="index"
                >
                  <div class="fontsix">
                    <div v-if="res.biaohong != ''" v-html="res.biaohong"></div>
                    <div v-else>{{ res.name }}</div>
                  </div>
                  <div class="flex align-items">
                    <span class="fontseven" style="margin-right: 3px">{{
                      "("
                    }}</span>
                    <span
                      class="fonteight jacu"
                      style="margin-right: 5px"
                      @click="bumen(res.name, res.id, 1, 'hong')"
                      >{{ res.weihuifu }}</span
                    >
                    <span class="fontseven">{{ "/" }}</span>
                    <span
                      @click="bumen(res.name, res.id, 1)"
                      class="fontseven jacu"
                      style="margin-left: 3px"
                      >{{ res.allcount }})</span
                    >
                  </div>
                </div>
              </div>
              <!-- 模板(热点部门)
              模板全部部门 -->
              <div class="shouli" v-else>
                <div class="donghua">
                  <div
                    class="list flex"
                    v-for="(item, index) in list"
                    :key="index"
                  >
                    <div class="dalei white-space">{{ item.name }}</div>
                    <div class="xiaofen flex">
                      <div
                        class="flex gezi"
                        v-for="(res, index) in item.child"
                        :key="index"
                      >
                        <div class="flex">
                          <div
                            v-if="res.biaohong != ''"
                            v-html="res.biaohong"
                          ></div>
                          <div v-else>{{ res.name }}</div>
                        </div>
                        <div>
                          <span>{{ "(" }}</span>
                          <span
                            class="fontOne cursor_pointer"
                            @click="bumen(res.name, res.id, 0, 'hong')"
                            >{{ res.weihuifu }}</span
                          >
                          <span>{{ "," }}</span>
                          <span @click="bumen(res.name, res.id, 0)" class="jacu"
                            >{{ res.allcount }})</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex chaxun space-between">
                    <div class="flex align-items">
                      <div class="flex zimu">
                        <div
                          class="mu"
                          v-for="(item, index) in zimu"
                          @click="shuruzimu(item)"
                          :key="index"
                        >
                          {{ item }}
                        </div>
                      </div>
                      <div class="sousu">
                        <div class="w-full h-full">
                          <el-input
                            class="inline-input"
                            v-model.trim="biaoin"
                            placeholder="您可输入要查找的单位"
                          >
                          </el-input>
                          <div class="fang">
                            <div class="fangda" @click="tgj"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex shuoming">
                      <div>{{ "说明 :" }}</div>
                      <div>{{ "绿色数字表示 :" }}</div>
                      <div class="green">{{ "已办结数" }}</div>
                      <div class="jianju">{{ "," }}</div>
                      <div>{{ "黑色数字表示 :" }}</div>
                      <div class="red">{{ "发帖总数" }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-else class="flex qubumen">
                左边大部门
                <div class="dabumen">
                  模板
                  <div
                    v-for="(item, index) in list"
                    :key="index"
                    class="daname"
                    @click="qiebumen(item, index)"
                  >
                    背景层
                    <div class="beiceng" v-if="dabumennum == index">
                      <div class="henglan"></div>
                      <img
                        src="../../assets/img/new/feidao.png"
                        class="feidao"
                        alt=""
                      />
                    </div>
                    内容层
                    <div
                      :class="{ lanzi: dabumennum == index }"
                      class="mingcneg"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>
                右边小单位
                <div class="xiaodanwei">
                  模板
                  <div
                    class="danweik"
                    v-for="(res, index) in danliast"
                    :key="index"
                    @click="bumen(res.name, res.id)"
                  >
                    <div class="fontsix nmae">
                      <div
                        v-if="res.biaohong != ''"
                        v-html="res.biaohong"
                      ></div>
                      <div v-else>{{ res.name }}</div>
                    </div>
                    <div class="flex align-items">
                      <span class="fontseven" style="margin-right: 3px">{{
                        "("
                      }}</span>
                      <span class="fonteight" style="margin-right: 5px">{{
                        res.weihuifu
                      }}</span>
                      <span class="fontseven">{{ "/" }}</span>
                      <span class="fontseven" style="margin-left: 3px"
                        >{{ res.allcount }})</span
                      >
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- 模板全部部门 -->
            </div>
          </div>
        </div>
        <!-- 查询部分 -->
        <!-- <div class="cahxun">
          <div class="flex align-items">
            <div class="zione">快速查询:</div>
            <div class="flex">
              字母模板
              <div
                @click="shuzimu(item)"
                class="zitwo"
                v-for="(item, index) in zimu"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            搜索框
            <div class="biaok">
              <el-input
                v-model.trim="biaoin"
                placeholder="您可输入要查找的单位"
              ></el-input>
              <div class="xian"></div>
              <img src="../../assets/img/new/sou.png" class="fangda" alt="" />
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <!-- 留言登选 -->
    <div class="w-full flex justify-content">
      <div class="dengxuan">
        <img src="../../assets/img/new/dengxuan.png" class="headt" alt="" />
        <!-- 标题 -->
        <div class="henggun">
          <!-- 咨询投诉建议 -->
          <div class="w-full flex space-between">
            <div
              class="sanxuan"
              :class="{ actheng: hengnum == 3 }"
              @click="kun(3)"
            >
              最新
            </div>
            <div
              class="sanxuan"
              :class="{ actheng: hengnum == 0 }"
              @click="kun(0)"
            >
              咨询
            </div>
            <div
              class="sanxuan"
              :class="{ actheng: hengnum == 1 }"
              @click="kun(1)"
            >
              建议
            </div>
            <div
              class="sanxuan"
              :class="{ actheng: hengnum == 2 }"
              @click="kun(2)"
            >
              投诉
            </div>
            <div
              class="sanxuan"
              :class="{ actheng: hengnum == 4}"
              @click="kun(4)"
            >
              编号
            </div>
          </div>

          <!-- 滚动条 -->
          <div class="xiaheng">
            <img
              src="../../assets/img/new/zhong.png"
              :class="{
                zj: hengnum == 1,
                zb: hengnum == 0,
                yb: hengnum == 2,
                zx: hengnum == 3,
                bh: hengnum == 4,
              }"
              class="weizhi"
              alt=""
            />
          </div>
        </div>
        <!-- 留言统计/满意恢复度 -->
        <!-- <div class="flex w-full flex_end hyul">
          <div class="flex">
            <div class="xiaolan" @click="jinbaifen(0)">
              <img
                src="../../assets/img/new/liuyan.png"
                class="liuyan"
                alt=""
              />
              <div class="fontliuyan">留言统计</div>
            </div>
            <div class="xiaohong" @click="jinbaifen(1)">
              <img src="../../assets/img/new/manyi.png" class="liuyan" alt="" />
              <div class="fontliuyan hong">回复满意度</div>
            </div>
          </div>
        </div> -->
        <!-- 表格 -->
        <div class="tab w-full">
          <!-- 头部 -->
          <div class="head">
            <div class="one">编号</div>
            <div class="two">标题</div>
            <div class="three">留言时间</div>
            <div class="four">受理单位</div>
            <div class="five">办理状态</div>
          </div>
          <!-- 身体 -->
          <div class="shen">
            <!-- 模板 -->
            <div
              class="hang"
              v-for="(item, index) in tieList"
              :key="index"
              @click="xiangqing(item.cardsalt)"
            >
              <div class="one tabfontone">{{ item.cardsalt }}</div>
              <div class="two tabfontone">{{ item.title }}</div>
              <div class="three tabfontone">{{ item.articletime2 }}</div>
              <div class="four tabfontone">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.department_name"
                  placement="top-start"
                  :disabled="gf(item.department_name)"
                >
                  <div>{{ buchang(item.department_name) }}</div>
                </el-tooltip>
                <!-- {{ item.department_name }} -->
              </div>
              <div class="five tabfonttwo" v-if="item.status_txt != null">
                <div class="red" v-if="item.status_txt == '待受理'">
                  {{ item.status_txt }}
                </div>
                <div class="ju" v-else-if="item.status_txt == '已受理'">
                  {{ item.status_txt }}
                </div>
                <div class="green" v-else-if="item.status_txt == '已回复'">
                  {{ item.status_txt }}
                </div>
                <div class="green" v-else-if="item.status_txt == '拟办结'">
                  {{ "已回复" }}
                </div>
                <div class="hui" v-else-if="item.status_txt == '已办结'">
                  {{ item.status_txt }}
                </div>
              </div>
              <div class="five tabfonttwo" v-else>
                <div class="red" v-if="item.status == '待受理'">
                  {{ item.status }}
                </div>
                <div class="ju" v-else-if="item.status == '已受理'">
                  {{ item.status }}
                </div>
                <div class="green" v-else-if="item.status == '已回复'">
                  {{ item.status }}
                </div>
                <div class="green" v-else-if="item.status == '拟办结'">
                  {{ "已回复" }}
                </div>
                <div class="hui" v-else-if="item.status == '已办结'">
                  {{ item.status }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 查看更多 -->
        <div class="w-full flex justify-content chageng">
          <div class="chaduo" @click="renewal">
            <div class="chazi">查看更多</div>
            <img src="../../assets/img/new/geng.png" class="chaxia" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 办理统计 -->
    <div class="w-full banli">
      <div class="w-full flex justify-content">
        <img src="../../assets/img/new/banli.png" class="tongjitu" alt="" />
      </div>
      <div class="banlibei">
        <div class="benfenlei">
          <div class="bankuan">
            <div class="cursor_pointer" @click="jinlie(0)">
              <div>
                <span class="shuzi">{{
                  xq == null ? 0 : xq.zhuti
                }}</span>
                <span class="jian">件</span>
              </div>
              <div class="shangxia">
                <img
                  src="../../assets/img/new/zhuti.png"
                  class="siwen"
                  alt=""
                />
                <div class="sifont">总主题数</div>
              </div>
            </div>
          </div>
          <div class="bankuan">
            <div class="cursor_pointer" @click="jinlie(1)">
              <div>
                <span class="shuzi">{{
                  xq == null ? 0 : xq.jrshouli
                }}</span>
                <span class="jian">件</span>
              </div>
              <div class="shangxia">
                <img
                  src="../../assets/img/new/shouli.png"
                  class="siwen"
                  alt=""
                />
                <div class="sifont">已受理数</div>
              </div>
            </div>
          </div>
          <div class="bankuan">
            <div class="cursor_pointer" @click="jinlie(2)">
              <div>
                <span class="shuzi">{{
                  xq == null ? 0 : xq.huifu
                }}</span>
                <span class="jian">件</span>
              </div>
              <div class="shangxia">
                <img
                  src="../../assets/img/new/yihuifu.png"
                  class="siwen"
                  alt=""
                />
                <div class="sifont">已回复数</div>
              </div>
            </div>
          </div>
          <div class="bankuan">
            <div class="cursor_pointer" @click="jinlie(3)">
              <div>
                <span class="shuzi">{{
                  xq == null ? 0 : xq.jrhuifu
                }}</span>
                <span class="jian">件</span>
              </div>
              <div class="shangxia">
                <img
                  src="../../assets/img/new/banjie.png"
                  class="siwen"
                  alt=""
                />
                <div class="sifont">已办结数</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 会员统计 -->
    <div class="huiyuan">
      <div class="zuoyounew">
        <!-- 左边 -->
        <div class="flex align-items">
          <div class="lan">{{ "在线人数" }}</div>
          <div style="opacity: 0">{{ "0" }}</div>
          <div>
            {{
              `- ${huiyaun == null ? 0 : huiyaun.all_num}人在线 - ${
                huiyaun == null ? 0 : huiyaun.user_num
              }会员
                                    ,${
                                      huiyaun == null
                                        ? 0
                                        : huiyaun.all_num - huiyaun.user_num
                                    } 位游客 - 最高记录是 ${
                huiyaun == null ? 0 : huiyaun.max_num
              } 于${huiyaun == null ? "2022-12-07" : huiyaun.max_riqi}`
            }}
          </div>
        </div>
        <!-- 右边 -->
        <div class="flex align-items">
          <div class="flex mr5">
            <div>{{ "今日:" }}</div>
            <div class="lan">{{ xinzeng == null ? 0 : xinzeng.jincout }}</div>
          </div>
          <div class="flex mr10">
            <div>{{ "昨日:" }}</div>
            <div class="lan">{{ xinzeng == null ? 0 : xinzeng.zuocout }}</div>
          </div>
          <!-- <div class="flex mr10">
            <div>{{ "帖子:" }}</div>
            <div class="lan">{{ xinzeng == null ? 0 : xinzeng.allcount }}</div>
          </div> -->
          <div class="flex">
            <div>{{ "欢迎新会员:" }}</div>
            <div class="lan">{{ xinzeng == null ? 0 : xinzeng.username }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { set } from "vue";
import API from "../../api/modules/home.js";
import wone from "./wanxin/index.vue";
import Bus from "@/assets/ligature";
export default {
  components: {
    wone,
  },
  inject: ["hasScrollbar"],
  data() {
    return {
      onetu: require("@/assets/img/new/guangao.png"),
      zhijian:require("@/assets/img/new/zhijian.png"),
      // twotu:require('@/assets/img/new/banli.png'),
      focusA: [], //四个热点聚焦
      luntitle: {
        // 轮播图名
        name: "",
        id: "",
      },
      arrimg: [], //轮播图
      xinzeng: null, //今日新增贴,昨日新增贴,总共贴,最新会员
      huiyaun: null, // 会员信息
      xq: null, //办理统计
      loading: null, //等待效果
      hengnum: 3, //选择咨询投诉或建议
      biaoin: "", //标红输入框
      lunnum: 0, //轮播图下标
      kaihenum: 2, //开合动画
      dabumennum: 0, //哪个大部门
      relist: [], //热点受理单位
      relistb: [], //热点单位标红
      list: [], //全部受理单位
      listb: [], //全部受理单位标红
      danliast: [], //当前选择的部门
      tieList: [], //帖子列表
      zimu: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    };
  },
  mounted() {
    this.unitList();
    localStorage.setItem("path", this.$route.path);
    this.articleCardList(3);
    this.banli();
    this.loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    this.allArticlenum();
    let timer = setTimeout(() => {
      this.info();
    }, 500);
    this.bannan();
    this.focusArticle();
  },
  watch: {
    "$store.state.huiyaun": function (vla, old) {
      this.huiyaun = vla;
    },
    biaoin: function (vla, old) {
      // if (this.kaihenum == 1) {
      //   this.list.forEach((item, index) => {
      //     this.quanbiao(item.child, str, index);
      //   });
      // } else {
      //   this.biao(this.relist, str);
      // }
      var str = vla.toUpperCase();
      this.list.forEach((item, index) => {
        this.quanbiao(item.child, str, index);
      });
    },
  },
  methods: {
    // 输入字母
    shuruzimu(str) {
      this.biaoin = this.biaoin + str;
    },
    // 退格键
    tgj() {
      this.biaoin = this.biaoin.substring(0, this.biaoin.length - 1);
    },
    guolue(str) {
      // var reg = /<[^>]+>/gi;  //过滤所有的html标签
      var reg = new RegExp("<[^>]+>", "gi"); //过滤所有的html标签，不包括内容
      let a = str.replace(reg, "");
      if (a == "") {
        return "网民朋友你好，你反映的问题我单位已收到，将尽快回复你。";
      }
      return a;
    },
    // 四个热点聚焦
    async focusArticle() {
      let res = await API.focusArticle().then((res) => {
        if (res.code == 1) {
          this.focusA = res.data;
          // this.focusA.forEach((item) => {
          //   item.content = this.guolue(item.content);
          // });
        }
      });
      // console.log(this.focusA, "十几块了了");
    },
    // 热点聚焦
    rjujiao() {
      // 搜素关键字:进入热点聚焦
      Bus.$emit("redianjujiao");
      // this.jump("hotfocus", this.$store.state.loginMessage);
    },
    //跳转到详情页
    godetail(id) {
      this.$store.state.loginMessage.wid = id;
      window.localStorage.setItem("xin", 1);
      const routeData = this.$router.resolve({
        path: "/infosdetail",
        query: {
          wid: id,
        },
      });
      // this.$store.state.loginMessage
      window.open(routeData.href, "_blank");
    },
    // 轮播图
    async bannan() {
      let res = await API.banner().then((res) => {
        if (res.code == 1) {
          this.arrimg = res.data;
          // 将第一张放入
          this.luntitle.name = this.arrimg[0].title;
          this.luntitle.id = this.arrimg[0].sharesalt;
        }
      });
    },
    // 进入列表页
    jinlie(num) {
      console.log(num, "sjk ");
      if (num != 0) {
        this.$store.state.loginMessage.distinguish = 1;
      }
      if (num == 0) {
        this.$store.state.yeming = "总主题数";
        this.$store.state.loginMessage.listkey = 0;
      } else if (num == 1) {
        this.$store.state.yeming = "已受理数";
        this.$store.state.loginMessage.listkey = 2;
      } else if (num == 2) {
        this.$store.state.yeming = "已回复数";
        this.$store.state.loginMessage.listkey = 3;
      } else if (num == 3) {
        this.$store.state.yeming = "已办结数";
        this.$store.state.loginMessage.listkey = 4;
      }
      // this.$store.state.loginMessage.classifNum = num;
      this.jumpDel(this.$store.state.loginMessage.listkey);

      this.jump("renewal", this.$store.state.loginMessage);
    },
    // 判断是否有top提示
    gf(str) {
      let a = str.split(",");
      if (a.length == 1) {
        return true;
      } else {
        return false;
      }
    },
    // 部门长度
    buchang(str) {
      let a = str.split(",");
      if (a.length == 1) {
        return a.toString();
      } else {
        return a[0] + "," + "...";
      }
    },
    // 进留言统计或者满意度回复
    jinbaifen(num) {
      this.jumpDel();
      if (num == 0) {
        this.jump("leaveword", this.$store.state.loginMessage);
      } else {
        this.jump("satisfaction", this.$store.state.loginMessage);
      }
    },
    // 跳向最新更新
    renewal() {
      if (this.hengnum == 0) {
        this.$store.state.loginMessage.price = 1;
        this.jump("renewalz", this.$store.state.loginMessage);
      }
      if (this.hengnum == 1) {
        this.$store.state.loginMessage.price = 7;
        this.jump("renewalj", this.$store.state.loginMessage);
      }
      if (this.hengnum == 2) {
        this.$store.state.loginMessage.price = 2;
        this.jump("renewalt", this.$store.state.loginMessage);
      }
      if (this.hengnum == 3 ) {
        this.$router.push({ name: 'renewals', params: { num: 0 }});
        return;
      }
      if (this.hengnum == 4) {
        this.$router.push({ name: 'renewals', params: { num: 4 }});
        return;
      }
      // 当前属于咨询还是投诉
      // this.$store.state.loginMessage.listkey = a;
      // this.$store.state.loginMessage.id = "";
      // this.$store.state.loginMessage.distinguish = 0;
      // this.$store.state.loginMessage.classifNum = 0;
      // this.jump("renewal", this.$store.state.loginMessage);
      // if (a == 2) {

      // }
    },
    // 跳入编写
    xie(num) {
      this.jumpDel();
      this.$store.state.leiixng = num;
      // this.jumps("issue", this.$store.state.loginMessage);
      this.$router.push("/issue");
    },
    // 特殊查询
    chaxunn(num) {
      this.jumpDel();
      if (num == 0) {
        this.jump("civilized", this.$store.state.loginMessage);
      } else {
        this.jump("woodpecker", this.$store.state.loginMessage);
      }
    },
    tozhijian(){
      this.$router.push("/zhijian");
    },
    // 今日昨日总贴数
    async allArticlenum() {
      let res = await API.allArticlenum().then((res) => {
        if (res.code == 1) {
          this.xinzeng = res.data;
        }
      });
    },
    // 在线会员
    async info() {
      let res = await API.info().then((res) => {
        if (res.code == 1) {
          this.huiyaun = res.data;
        }
      });
    },
    // 办理统计
    async banli() {
      let res = await API.artileNums().then((res) => {
        if (res.code == 1) {
          this.xq = res.data;
        }
      });
    },
    // 数字超过3四位后加入逗号
    shuchuli(num) {
      let str = String(num);
      if (str.length <= 3) {
        return num;
      } else {
        let a = str.split("");
        let b = 0;
        let c = "";
        a.forEach((item) => {
          if (b == 3) {
            c = c + "，" + item;
            b = 0;
            // console.log(b, "111");
          } else {
            c = c + item;
            b = b + 1;
          }
        });
        return c;
      }
    },
    // 跳转至详情
    xiangqing(id) {
      this.$store.state.loginMessage.cardsalt = id;
      // localStorage.setItem("cardsalt", id);
      localStorage.setItem("cardsalttt", id);
      let times = setTimeout(() => {
        let routeData = this.$router.resolve({
          name: "details",
          query: {
            cardsalt: id,
          },
          // this.$store.state.loginMessage,
        });
        window.open(routeData.href, "_blank");
      }, 100);
    },
    // 帖子列表
    // kindeNum : 类型:0=最新更新,1=编号,2=热点,3=热评
    // classifNum : 类别:0=无,1=待回复,2=已回复,3=咨询,4=投诉,5=建议
    // type	类别:0=咨询,1=投诉,2=建议,3=全部
    async articleCardList(types) {
      let aa = 1;
      
      if (types == 3) {
        aa = 0;
      }
      if (types == 4) {
        types = 3
        aa = 1;
      }
      let res = await API.articleCardList({
        kind: aa,
        classif: 0,
        type: types,
        artstime: 0,
        genres: 0,
        departid: 0,
        page: 1,
        pageLine: 10,
      })
        .then((res) => {
          if (res.code == 1) {
            this.tieList = res.data.list;
            this.loading.close();
          } else {
            this.loading.close();
          }
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 选择咨询投诉或建议
    kun(num) {
      this.tieList = [];
      this.hengnum = num;
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let a = 0;
      if (num == 0) {
        a = 0;
      }
      if (num == 1) {
        a = 2;
      }
      if (num == 2) {
        a = 1;
      }
      if (num == 3) {
        a = 3;
      }
      if (num == 4) {
        a = 4;
      }
      this.articleCardList(a);
    },
    // 按部门查找帖子
    async bumen(name, id, y, h) {
      // 代表是点的红色数字,是已办结的帖子
      localStorage.setItem("hong", 0);
      let aa = 0
      if (h == "hong") {
        localStorage.setItem("hong", 4);
        aa = 4
      }
      this.$store.state.loginMessage.yaer = y;
      this.$store.state.loginMessage.bm = id;

      let routeData = this.$router.resolve({
        name: "unit",
        query: {
          bm: id,
          yaer: y,
          check:aa
        },
      });
      // this.$store.state.loginMessage
      window.open(routeData.href, "_blank");
      // this.jump("unit", this.$store.state.loginMessage);
    },
    // 切换部门
    qiebumen(obj, index) {
      this.danliast = [];
      this.dabumennum = index;
      this.danliast = obj.child;
    },
    // 点击字母
    shuzimu(str) {
      this.biaoin = this.biaoin + str;
    },
    // 热点单位标红
    biao(obj, str) {
      let c = [];
      let b = 0;
      let a = 0;
      let d = 0;
      let f = 0;
      let g = "";
      // 判断是文字还是英文 如果返回-1代表不是字母,反之就是字母
      // 取第一个字母,多字母就无法判定了
      let shouzi = str.slice(0, 1);
      let pan = this.zimu.indexOf(shouzi);
      if (pan != -1) {
        // 英文标红
        obj.forEach((item, index) => {
          // 查询字符在字母里的位置,获取起始下标
          a = item.allnum.indexOf(str, 0);
          if (a != -1) {
            //    console.log(a,item.name,'222');
            // 获取下标
            f = index;
            // console.log(f,'下标');
            // 获取查询的字符窜长度
            b = str.length;
            // 将文字转为数组
            let e = item.name.split("");
            // console.log(e,'0000');
            e.forEach((item, index) => {
              c.push({
                name: item,
                zt: false,
              });
            });
            // 改变状态
            c.forEach((item, index) => {
              if (d == b) {
                return;
              } else {
                d = d + 1;
                c[a].zt = true;
                a = a + 1;
              }
            });
            // 拼接html
            c.forEach((item, index) => {
              if (item.zt == false) {
                g = g + `<span>${item.name}</span>`;
              } else {
                g =
                  g +
                  `<span style=' color: #C90E0E !important;font-weight: 700;'>${item.name}</span>`;
              }
            });
            this.relist[f].biaohong = g;
            c = [];
            g = [];
            d = 0;
          } else {
            item.biaohong = "";
          }
        });
      } else {
        // 中文标红
        let cc = [];
        let bb = 0;
        let aa = 0;
        let dd = 0;
        let ff = 0;
        let gg = "";
        obj.forEach((item, index) => {
          // 查询文字在里的位字符里的其实位置置,获取起始下标
          aa = item.name.indexOf(str, 0);
          // 如果aa不等于-1则表示查到有内容
          if (aa != -1) {
            // 获取下标
            ff = index;
            // 获取查询的字符窜长度
            bb = str.length;
            // 将文字转为数组
            let ee = item.name.split("");
            // 将它每个文字都拆分
            ee.forEach((item, index) => {
              cc.push({
                name: item,
                zt: false,
              });
            });
            // 对比文字改变状态
            cc.forEach((item, index) => {
              if (dd == bb) {
                return;
              } else {
                dd = dd + 1;
                cc[aa].zt = true;
                aa = aa + 1;
              }
            });
            // 拼接html
            cc.forEach((item, index) => {
              if (item.zt == false) {
                gg = gg + `<span>${item.name}</span>`;
              } else {
                gg =
                  gg +
                  `<span style=' color: #C90E0E !important;font-weight: 700;'>${item.name}</span>`;
              }
            });
            this.relist[ff].biaohong = gg;
            cc = [];
            gg = [];
            dd = 0;
          } else {
            item.biaohong = "";
          }
        });
      }
    },
    // 全部单位标红
    quanbiao(obj, str, xia) {
      let c = [];
      let b = 0;
      let a = 0;
      let d = 0;
      let f = 0;
      let g = "";
      // 判断是文字还是英文 如果返回-1代表不是字母,反之就是字母
      let shouzi = str.slice(0, 1);
      let pan = this.zimu.indexOf(shouzi);
      if (pan != -1) {
        // 英文标红
        obj.forEach((item, index) => {
          // 查询字符在字母里的位置,获取起始下标
          a = item.allnum.indexOf(str, 0);

          if (a != -1) {
            //    console.log(a,item.name,'222');
            // 获取下标
            f = index;
            // console.log(f,'下标');
            // 获取查询的字符窜长度
            b = str.length;
            // 将文字转为数组
            let e = item.name.split("");
            // console.log(e,'0000');
            e.forEach((item, index) => {
              c.push({
                name: item,
                zt: false,
              });
            });
            // 改变状态
            c.forEach((item, index) => {
              if (d == b) {
                return;
              } else {
                d = d + 1;
                c[a].zt = true;
                a = a + 1;
              }
            });
            // 拼接html
            c.forEach((item, index) => {
              if (item.zt == false) {
                g = g + `<span>${item.name}</span>`;
              } else {
                g =
                  g +
                  `<span style=' color: #C90E0E !important;font-weight: 700;'>${item.name}</span>`;
              }
            });
            this.list[xia].child[f].biaohong = g;
            c = [];
            g = [];
            d = 0;
          } else {
            item.biaohong = "";
          }
        });
        // 拼音标红
        // 先将字母全部小写
        let py = str.toLowerCase();
        if (py.length <= 1) {
          return;
        }
        this.list.forEach((item) => {
          item.child.forEach((res) => {
            // 将拼音转为数组
            let a = res.pinying.split(" ");
            let b = res.name.split("");
            // 循环拼音数组
            let e = "";
            // 将拼音组合在一起
            a.forEach((str, num) => {
              if (e == "") {
                e = str;
              } else {
                e = e + str;
              }
            });
            let reg = new RegExp(py);
            let result = reg.test(e);
            let c = "";
            if (result) {
              b.forEach((wenzi) => {
                wenzi = `<span style=' color: #C90E0E !important;font-weight: 700;'>${wenzi}</span>`;
                if (c == "") {
                  c = wenzi;
                } else {
                  c = c + wenzi;
                }
              });
              res.biaohong = c;
            } else {
              res.biaohong = "";
            }
          });
        });
      } else {
        // 中文标红
        let cc = [];
        let bb = 0;
        let aa = 0;
        let dd = 0;
        let ff = 0;
        let gg = "";
        obj.forEach((item, index) => {
          // 查询文字在里的位字符里的其实位置置,获取起始下标
          aa = item.name.indexOf(str, 0);
          // 如果aa不等于-1则表示查到有内容
          if (aa != -1) {
            // 获取下标
            ff = index;
            // 获取查询的字符窜长度
            bb = str.length;
            // 将文字转为数组
            let ee = item.name.split("");
            // 将它每个文字都拆分
            ee.forEach((item, index) => {
              cc.push({
                name: item,
                zt: false,
              });
            });
            // 对比文字改变状态
            cc.forEach((item, index) => {
              if (dd == bb) {
                return;
              } else {
                dd = dd + 1;
                cc[aa].zt = true;
                aa = aa + 1;
              }
            });
            // 拼接html
            cc.forEach((item, index) => {
              if (item.zt == false) {
                gg = gg + `<span>${item.name}</span>`;
              } else {
                gg =
                  gg +
                  `<span style=' color: #C90E0E !important;font-weight: 700;'>${item.name}</span>`;
              }
            });
            this.list[xia].child[ff].biaohong = gg;
            cc = [];
            gg = [];
            dd = 0;
          } else {
            item.biaohong = "";
          }
        });
      }
    },
    // 受理单位(包含热点单位)
    async unitList() {
      let one = await API.hotunitList().then((res) => {
        if (res.code == 1) {
          this.relist = res.data.slice(0, 10);
          this.relist.forEach((item, index) => {
            item["biaohong"] = "";
          });
        }
      });
      let res = await API.unitList().then((res) => {
        if (res.code == 1) {
          this.list = res.data;
          this.list.forEach((item, index) => {
            this.zixiang(item.child);
          });
          this.listb = res.data;
          this.listb.forEach((item, index) => {
            this.zixiang(item.child);
          });
        }
      });
    },
    zixiang(obj) {
      obj.forEach((item, index) => {
        item["biaohong"] = "";
      });
    },
    // 动画开合
    kaihe() {
      this.biaoin = "";
      if (this.kaihenum == 1) {
        this.kaihenum = 0;
      } else {
        this.danliast = [];
        setTimeout(() => {
          this.qiebumen(this.list[0], 0);
        }, 1000);
        this.kaihenum = 1;
      }
    },
    // 轮播图当前第几张图
    jizhang(e) {
      this.lunnum = e;
      this.luntitle.name = this.arrimg[e].title;
      this.luntitle.id = this.arrimg[e].sharesalt;
      // console.log(e);
    },
    // 文字长度限制
    wenxian(str) {
      var reg = new RegExp("<[^>]+>", "gi"); //过滤所有的html标签，不包括内容
      let a = str.replace(reg, "");
      a = a.replace(/&nbsp;/g, '');
      if (a.length > 30) {
        return a.slice(0, 25) + "...";
      } else {
        return a ;
      }
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem("openname");
  },
};
</script>

<style scoped lang="scss">
._firstPage {
  width: 100%;
  padding: 0px 0 11px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lun {
  width: 1200px;
  display: flex;
  margin-bottom: 53px;
  .zuo {
    width: 668.44px;
    height: 376px;
    margin-right: 15px;
    position: relative;
  }
  .you {
    flex: 1;
    max-width: calc(528px - 0.44px);
  }
}
.zhanshi {
  position: absolute;
  width: 100%;
  height: 46px;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  padding: 8px 25px 10px 31px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quan {
  width: 8px;
  height: 8px;
  margin-right: 8px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
::v-deep(.el-carousel) {
  height: 100%;
}
::v-deep(.el-carousel__container) {
  height: 100%;
}
::v-deep(.el-carousel__indicators--outside) {
  display: none;
}
.fontone {
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
  flex: 1;
  overflow: hidden; /* 隐藏超出部分的文本内容 */
  text-overflow: ellipsis; /* 使用省略号表示被隐藏的文本内容 */
  white-space: nowrap; /* 防止文本换行 */
}
.redian {
  width: 100%;
  min-width: 1200px;
  min-height: 650px;
  background-image: url("../../assets/img/new/redian.jpg");
  background-size: 100% 100%;
  margin-bottom: 65px;
  display: flex;
  justify-content: center;
}
._re {
  width: 1200px;
  height: 100%;
  padding: 35px 0 53px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.left {
  width: 630px;
  margin-right: 32px;
  .leftt {
    width: 630px;
  }

  .biao {
    width: 100%;
    height: 53px;
    display: flex;
    justify-content: center;
  }
  .cardd {
    width: 100%;
    height: 426px;
    background-size: 100% 100%;
    margin-top: 34px;
    background: rgba(244, 249, 255, 0.9);
    box-shadow: 0 8px 27px 0 rgba(69, 78, 107, 0.38);
    padding: 0.1px 25px 24px 45px;
    box-sizing: border-box;
    .wen {
      width: 26px;
      height: 26px;
      margin-right: 12px;
      img {
        width: 26px;
        height: 26px;
      }
    }
    .huida {
      margin-top: 9px;
      .da {
        width: 26px;
        height: 26px;
        margin-right: 12px;
        img {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
  .duo {
    margin-top: 21px;
  }
  .geng {
    width: 18px;
    height: 18px;
    margin-left: 5px;
  }
}
.right {
  flex: 1;

  .biaot {
    display: flex;
    justify-content: center;
    img {
      width: 561px;
      height: 53px;
    }
  }
  .cardds {
    width: 100%;
    min-height: 426px;
    background-image: url("../../assets/img/new/dankuang.png");
    background-size: 100% 100%;
    margin-top: 34px;
    background: rgba(244, 249, 255, 0.9);
    box-shadow: 0 8px 27px 0 rgba(69, 78, 107, 0.38);
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
  }
  .pbj {
    padding: 17px 27px 14px 12px;
  }
  .zhankai {
    position: absolute;
    top: 0;
    right: -48px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .zhan {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
    }
    .ziti {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #005697;
      letter-spacing: 0;
      font-weight: 400;
      line-height: 17px;
    }
  }
}
.fonttwo {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #18191a;
  font-weight: 600;
}
::v-deep .fontThre {
 p {
  all: unset;
  font-family: PingFangSC-Regular !important;
    font-size: 16px !important;
  color: #313235 !important;
  font-weight: 400 !important;
  background: none  !important;
  margin: 0 !important;
  white-space: nowrap  !important;  /*内容超宽后禁止换行显示*/
		overflow: hidden !important;    /*超出部分隐藏*/
		text-overflow: ellipsis !important;
    padding: none !important;
  }
  span{
  all: unset;
  font-family: PingFangSC-Regular !important;
  padding: none !important;
    font-size: 16px !important;
  color: #313235 !important;
  font-weight: 400 !important;
  background: none  !important;
  margin: 0 !important;
  white-space: nowrap  !important;  /*内容超宽后禁止换行显示*/
		overflow: hidden !important;    /*超出部分隐藏*/
		text-overflow: ellipsis !important;
    padding: none !important;
  }
  font {
    all: unset;
    font-family: PingFangSC-Regular !important;
    font-size: 16px !important;
  color: #313235 !important;
  font-weight: 400 !important;
  background: none  !important;
  margin: 0 !important;
  white-space: nowrap  !important;  /*内容超宽后禁止换行显示*/
		overflow: hidden !important;    /*超出部分隐藏*/
		text-overflow: ellipsis !important;
    padding: none !important;
  }
  all: unset;
  height: 26px !important;
  font-family: PingFangSC-Regular !important;
  font-size: 16px !important;
  color: #313235 !important;  
  font-weight: 400 !important;
		overflow: hidden;     /*超出部分隐藏*/
		text-overflow: ellipsis; 
    padding: 0 !important;
}
.fontfour {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #484c51;
  text-align: right;
  font-weight: 400;
  float: right;
}
.fontfive {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #0073ed;
  letter-spacing: 0;
  font-weight: 400;
}
.zuoyin {
  overflow: hidden;
  animation: yin 1s infinite forwards;
  animation-iteration-count: 1;
}
@keyframes yin {
  0% {
    width: 630px;
  }
  100% {
    width: 0px;
  }
}
.zuohui {
  animation: zuohui 1.2s infinite forwards;
  animation-iteration-count: 1;
}
@keyframes zuohui {
  0% {
    overflow: hidden;
    width: 0px;
  }
  99% {
    overflow: hidden;
    width: 630px;
  }
  100% {
    overflow: none !important;
  }
}
.redanwei:nth-child(odd) {
  margin-right: 45px;
}
.redanwei {
  width: 220px;
  height: 45px;
  margin-left: 18px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 13px 0px 25px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid rgba(99, 132, 169, 0.21);
  cursor: pointer;
  &:hover {
    background: #f4f9ff;
    border: 1px solid #5e92f1;
    box-shadow: 0 8px 27px 0 rgba(69, 78, 107, 0.38);
  }
}
.fontsix {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #4b688c;
  letter-spacing: 0;
  font-weight: 500;
}
.fontseven {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #6384a9;
  letter-spacing: 0;
  font-weight: 500;
}
.fonteight {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #28C445;
  letter-spacing: 0;
  font-weight: bold;
}
.cahxun {
  margin-top: 19px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .zione {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #484c51;
    letter-spacing: 0;
    font-weight: 400;
    margin-right: 8px;
  }
  .zitwo {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #484c51;
    letter-spacing: 0;
    font-weight: 400;
    margin-right: 8px;
    cursor: pointer;
  }
  .biaok {
    width: 211px;
    height: 36px;
    background: #ffffff;
    border: 1px solid rgba(99, 132, 169, 0.21);
    margin-left: 5px;
    position: relative;
    padding: 0 0 0 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .xian {
      width: 0.5px;
      height: 19px;
      border: 1px solid #2670c5;
    }
    ::v-deep(.el-input) {
      width: 81%;
    }
    ::v-deep(.el-input__inner) {
      line-height: 0 !important;
      height: 100% !important;
      border: 0px solid;
      &::placeholder {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
  .fangda {
    position: absolute;
    width: 18px;
    height: 18px;
    top: calc(50% - 9px);
    right: 13px;
    z-index: 10;
  }
}
.qubumen {
  .dabumen {
    width: 228px;
    height: 100%;
    background-image: linear-gradient(180deg, #ffffff 2%, #dfedfd 99%);
    display: flex;
    flex-direction: column;
    padding: 30px 0 30px 0;
    box-sizing: border-box;
    justify-content: space-between;
    .daname {
      width: 100%;
      height: 52px;
      font-family: PingFangSC-Medium;
      position: relative;
      .mingcneg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        font-size: 18px;
        color: #4b688c;
        letter-spacing: 0;
        font-weight: 600;
        text-align: center;
        line-height: 52px;
        cursor: pointer;
        &:hover {
          font-family: PingFangSC-Medium;
          color: #0262d6;
          letter-spacing: 0;
          font-weight: 600;
        }
      }
      .beiceng {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px 0 0;
        box-sizing: border-box;
        background-image: url("../../assets/img/new/xiaotop.png");
        background-size: cover;
        .henglan {
          width: 2px;
          height: 100%;
          border: 1px solid #006ae2;
          background: #006ae2;
        }
        .feidao {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}
.xiaodanwei {
  flex: 1;
  padding: 24px 20px 13px 7px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  .danweik {
    width: 205px;
    height: 45px;
    margin-bottom: 12px;
    margin-left: 18px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 13px 0 25px;
    background: #ffffff;
    border: 1px solid rgba(99, 132, 169, 0.21);
    cursor: pointer;
    .nmae {
      font-size: 14px !important;
    }
    &:hover {
      background: #f4f9ff;
      border: 1px solid #5e92f1;
      box-shadow: 0 8px 27px 0 rgba(69, 78, 107, 0.38);
    }
  }
}
.lanzi {
  font-family: PingFangSC-Medium;
  color: #0262d6 !important;
  font-weight: 600 !important;
}
// 留言登选
.dengxuan {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .headt {
    width: 480px;
    height: 53px;
    margin-bottom: 53px;
  }
  .henggun {
    width: 380px;
  }
  .sanxuan {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    color: #5b799c;
    letter-spacing: 0;
    font-weight: 600;
    padding: 0 10px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      font-family: PingFangSC-Medium;
      font-size: 24px;
      color: #262729;
      font-weight: 600;
    }
  }
  .xiaheng {
    width: 100%;
    position: relative;
    margin-bottom: 13.5px;
    .weizhi {
      position: absolute;
      left: 0;
      top: 0px;
      width: 61.3px;
      z-index: 10;
    }
  }
  .actheng {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    color: #262729;
    font-weight: 600;
  }
}
.zj {
  left: calc(40% + 10px) !important;
  transition: all 0.5s ease;
}
.zb {
  left: calc(20% + 6px) !important;
  transition: all 0.5s ease;
}

.yb {
  left: calc(80% - 68px) !important;
  transition: all 0.5s ease;
}
.zx {
  left: 0 !important;
  transition: all 0.5s ease;
}
.bh {
  left: calc(100% - 65.3px) !important;
  transition: all 0.5s ease;
}
.hyul {
  .xiaolan {
    width: 154px;
    height: 53px;
    background: #ffffff;
    border: 1px solid #3079df;
    border-radius: 35px;
    padding: 8px 17px 7px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
  }
  .xiaohong {
    cursor: pointer;
    margin-left: 16px;
    width: 171px;
    height: 53px;
    background: #ffffff;
    border: 1px solid #e55452;
    border-radius: 35px;
    padding: 8px 17px 7px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hong {
      font-family: PingFangSC-Regular;
      font-size: 18px;
      color: #e44a4f;
      font-weight: 400;
    }
  }
  .liuyan {
    width: 38px;
    height: 38px;
  }
  .fontliuyan {
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #005697;
    letter-spacing: 0;
    font-weight: 400;
  }
}

.tab {
  .head {
    width: 100%;
    background-image: linear-gradient(180deg, #d1e1f9 0%, #f0f6fe 100%);
    height: 58px;
    padding: 19px 0 11px 0;
    box-sizing: border-box;
    display: flex;
    font-family: PingFangSC-Medium;
    font-size: 20px;
    color: #262729;
    font-weight: 600;
    align-items: center;
  }
  .one {
    flex: 1.2;
    text-align: center;
  }
  .two {
    flex: 3.3;
    text-align: center;
  }
  .three {
    flex: 1;
    text-align: center;
  }
  .four {
    flex: 1;
    text-align: center;
  }
  .five {
    flex: 1;
    text-align: center;
  }
  .shen {
    .hang {
      width: 100%;
      height: 59px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .hang:nth-child(even) {
      background: #f1f7ff;
    }
  }
  .tabfontone {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #262729;
    letter-spacing: 0;
    font-weight: 400;
  }
  .tabfonttwo {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #37b089;
    font-weight: 400;
  }
}
.chageng {
  margin-top: 19px;
  margin-bottom: 41px;
  .chaduo {
    width: 140px;
    height: 46px;
    background: #f1f7ff;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .chazi {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #0073ed;
    font-weight: 400;
  }
  .chaxia {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
}
.banli {
  .tongjitu {
    width: 480.29px;
    height: 53px;
    margin-bottom: 27px;
  }
  .banlibei {
    width: 100%;
    min-width: 1300px;
    min-height: 282px;
    background-image: url("../../assets/img/new/banbj.jpg");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
  }
  .benfenlei {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bankuan {
    width: 291px;
    height: 192px;
    // background-image: url('../../assets/img/new/banjuan.png');
    // background-size: 100% 100%;
    background: rgba(255, 255, 255, 0.19);
    border-radius: 4px;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .shuzi {
      font-family: SourceHanSerifSC-Heavy;
      font-size: 30px;
      color: #ffd200;
      font-weight: 900;
    }
    .jian {
      font-family: PingFangSC-Regular;
      font-size: 18px;
      color: #ffffff;
      font-weight: 400;
      margin-left: 19px;
    }
    .shangxia {
      display: flex;
      align-items: center;
      margin-top: 17px;
      .siwen {
        width: 26px;
        height: 26px;
        margin-right: 5px;
      }
      .sifont {
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
.huiyuan {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  .zuoyounew {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #191a1c;
    font-weight: 400;
  }
  .lan {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #2670c5;
    font-weight: 400;
  }
  .mr5 {
    margin-right: 5px;
  }
  .mr10 {
    margin-right: 5px;
  }
}
.min_width {
  min-width: 1300px;
}

.liangjie {
  width: 100%;
  height: 480px;
  background: #064f9d;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  .guan {
    width: 1200px;
    height: 145px;
    margin-bottom: 16px;
  }
  .zhijian {
    margin-top: 16px;
    width: 1200px;
    height: 100px;
    margin-bottom: 16px;
  }
  .why {
    width: 1200px;
    height: 166px;
    border-radius: 4px;
    background-image: url("../../assets/img/new/dise.png");
    background-size: 100% 100%;
    padding: 28px 0 25px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .fang {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: PingFangSC-Semibold;
    font-size: 22px;
    color: #0b52b6;
    font-weight: 600;
    .icon {
      width: 68px;
      height: 68px;
      margin-bottom: 13px;
    }
    cursor: pointer;
  }
}
.gengbiaoti {
  flex: 1;
  overflow: hidden; /* 隐藏超出部分的文本内容 */
  text-overflow: ellipsis; /* 使用省略号表示被隐藏的文本内容 */
  white-space: nowrap; /* 防止文本换行 */
}
.xixian {
  width: 1px;
  height: 60px;
  border-left: 2px solid #9bafc5;
}
.xzb {
  transform: rotate(180deg);
  transition: all 0.5s linear;
}
.hutoub {
  transform: rotate(0deg);
  transition: all 0.5s linear;
}
// 受理单位
.shouli {
  // margin-top: 14px;
  width: 1200px;
  // background-image: url('../../assets/img/xinban/kuangBJ.png');
  // background-size: 100% 100%;
  padding: 22px 75px 40px 19px;
  box-sizing: border-box;
  position: relative;
  background: #fff;
  border: 1px solid #e6e6e6;
  // margin-bottom: 20px;
  .qie {
    position: absolute;
    left: -33.63px;
    width: 33.63px;
    height: 129.22px;
    background-image: url("../../assets/img/xinban/cebiao.png");
    background-size: 100% 100%;
  }

  .list {
    width: 100%;
    border-bottom: 1px dashed rgba(126, 126, 126, 0.19);
    padding-bottom: 0px;
    margin-bottom: 10px;
    padding-left: 30px;
    box-sizing: border-box;
  }

  .dalei {
    width: 150px;
    font-family: PingFangSC-Semibold;
    font-size: 15px;
    color: #0653ac;
    letter-spacing: 0;
    font-weight: 600;
  }
  .redian {
    width: 150px;
    font-family: "黑体";
    font-size: 16px;
    color: #064376;
    letter-spacing: 0;
    font-weight: 600;

    .reimg {
      margin-top: 20px;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      margin-bottom: 5px;
      margin-right: 36px;
    }
    .refont {
      text-align: center;
      margin-right: 22px;
    }
  }
  .xian {
    width: 0.5px;
    height: 130px;
    border-right: 1px solid rgba(126, 126, 126, 0.19);
    margin-right: 30px;
  }
  .xiaofen {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;
    width: 100%;
    flex-wrap: wrap;

    .gezi {
      width: 16.5%;
      margin-bottom: 10px;
    }

    .fontOne {
      color: #28C445;
      font-weight: bold;
      &:hover {
        font-weight: 700;
      }
    }
  }

  .chaxun {
    align-items: center;
    margin-top: 21px;
  }

  .zimu {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: #484c51;
    letter-spacing: 2.02px;
    text-align: justify;
    font-weight: 400;
    margin-right: 16px;

    .mu {
      margin-right: 8px;
      cursor: pointer;
      user-select: none;
    }
  }

  //搜索
  .sousu {
    width: 220px;
    height: 36px;
    // border: 0.5px solid #CCCCCC;
    position: relative;
    border-radius: 50%;

    ::v-deep(.el-autocomplete) {
      width: 100% !important;
      height: 100% !important;
    }

    ::v-deep(.el-input) {
      height: 100% !important;
    }

    ::v-deep .el-input__inner {
      border: 1px solid #cccccc;
      height: 100%;
      background: #ffffff;
      border-radius: 17px;
    }

    ::v-deep .el-input__inner::-webkit-input-placeholder {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #8890a2;
      letter-spacing: 0;
      font-weight: 400;
    }

    .fang {
      padding: 0 0 0 18px;
      position: absolute;
      right: 1vw;
      top: calc(50% - 10px);
      border-left: 1px solid #2670c5;
      height: 19px;
    }

    .fangda {
      width: 20px;
      height: 20px;
      background-image: url("~@/assets/img/xinban/tg.png");
      background-size: 100% 100%;

      cursor: pointer;
    }
  }

  .fontThree {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: #0653ac;
    letter-spacing: 1px;
    font-weight: 400;
  }

  .zhankai {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
}
.yuan {
  position: absolute;
  // width: 30px;
  // height: 30px;
  bottom: 15px;
  left: calc(50% - 40px);
  z-index: 20;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
    transform: rotate(90deg);
    transition: all 0.5s ease;
  }
}
.red {
  color: #c90e0e !important;
  font-size: 14.5px !important;
  font-weight: 500;
}
.green {
  color: #28C445 !important;
  font-size: 14.5px !important;
  font-weight: 500;
}

.dingwei {
  position: relative;
}
.biaozuo {
  position: absolute;
  right: 39px;
  bottom: -30px;
  z-index: 10;
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #313235;
  font-weight: 400;
}
.jacu:hover {
  cursor: pointer;
  font-weight: 700;
}
.hand-cursor {
  cursor: pointer; /* 默认的手指形状 */
}
 
.hand-cursor:hover {
  cursor: pointer; /* 鼠标悬停时仍为手指形状 */
  /* 如果需要变成手形，可以使用下面的代码 */
  /* cursor: hand; 这在CSS标准中已经不推荐使用，可以使用url加图片或者其他值替代 */
}
</style>